@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-top-right-radius: 100vh;
  border-bottom-right-radius: 100vh;
}

::-webkit-scrollbar-thumb {
  background: #969494;
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb:hover {
  background: #576a8f;
}

.btn {
  @apply rounded-md font-semibold capitalize transition-all flex items-center justify-center gap-2;
}

.btn-sm {
  @apply px-4 h-8 text-xs;
}
.btn-md {
  @apply px-4 h-10 text-[12px];
}
.btn-lg {
  @apply px-5 h-12 text-sm;
}
.btn-xl {
  @apply px-5 h-14 text-sm;
}

.btn-primary {
  background: linear-gradient(96.41deg, #2dc1ce -16.07%, #223561 91.65%);
  @apply text-white;
}

.btn-danger {
  @apply bg-danger border border-solid border-danger hover:bg-[#f31225] text-white;
}

.btn-warning {
  @apply bg-warning border border-solid border-warning hover:bg-[#edb23b] text-white;
}

.btn-success {
  @apply bg-success border border-solid border-success hover:bg-[#11c544] text-white;
}

.btn-default {
  @apply bg-slate-50 !text-slate-500 border border-solid border-slate-200 hover:bg-slate-100;
}

.btn-secondary {
  @apply bg-primary-shade !text-slate-500 border border-solid border-primary-shade;
}

.btn-disabled {
  opacity: 0.6;
}

.btn-outlined {
  background: transparent !important;
  @apply text-slate-600;
}
.btn-outlined.btn-danger {
  @apply !text-danger;
}
.btn-outlined.btn-primary {
  border: 1px solid #223561;
}

.active_menu {
  @apply !bg-primary !text-white;
}

.card {
  @apply p-4 md:p-6 bg-white rounded-md;
}

/* badge color */
.badge-success {
  @apply !text-success;
}
.badge-danger {
  @apply !text-danger;
}
.badge-info {
  @apply !text-info;
}
.badge-warning {
  @apply !text-warning;
}

/* table css */
table {
  width: 100%;
}

table thead tr td {
  @apply text-dark text-sm pb-2;
}

table tbody tr td {
  @apply text-dark text-sm font-medium py-4;
}

.react-date-picker__inputGroup input:focus {
  outline: none;
  background-color: unset;
  border: unset;
  box-shadow: unset;
  user-select: none;
}

.react-calendar__tile--active {
  background: #006edc;
  color: #fff;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__wrapper input {
  background: transparent !important;
}
